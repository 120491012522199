import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { Asset } from "contentful";
import React from "react";
import { ImageRenderer } from "../../components/ImageRenderer";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { lightTextOpacity } from "../../theme";
import { RichTextDocument } from "../../types/contentful";

export interface ContactUsItemFields {
  title?: RichTextDocument;
  description?: RichTextDocument;
  icon?: Asset;
}

interface ContactUsItemProps {
  fields: ContactUsItemFields;
}

const imageSizes = [
  {
    mediaQuery: "default",
    params: { w: 32 },
  },
];

export const ContactUsItem: React.FC<ContactUsItemProps> = ({ fields }) => {
  return (
    <HStack spacing={4} align="flex-start">
      {fields.icon && (
        <Flex mt={0.5} justify="center">
          <ImageRenderer asset={fields.icon} sizes={imageSizes} w="32px" />
        </Flex>
      )}

      <VStack spacing={2} align="flex-start">
        {fields.title && (
          <Box textStyle="title" fontSize="24px">
            <RichTextRenderer text={fields.title} as="h3" />
          </Box>
        )}

        {fields.description && (
          <Box opacity={lightTextOpacity}>
            <RichTextRenderer text={fields.description} />
          </Box>
        )}
      </VStack>
    </HStack>
  );
};
