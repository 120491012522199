import { Container, ContainerProps } from "@chakra-ui/react";
import React from "react";

export const pageContainerMobilePadding = ["1.5rem", null, "2rem", null];

export const PageContainer: React.FC<ContainerProps> = ({
  children,
  ...props
}) => {
  return (
    <Container
      {...props}
      maxW="container.xl"
      paddingInlineStart={pageContainerMobilePadding}
      paddingInlineEnd={pageContainerMobilePadding}
    >
      {children}
    </Container>
  );
};
