import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Spacer,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Entry } from "contentful";
import Link from "next/link";
import React, { useCallback, useEffect, useRef } from "react";
import {
  DividerFields,
  LanguageFields,
  UntranslatedField,
} from "../types/contentful";
import { ButtonFields, CmsButton, mapButtonProps } from "./CmsButton";
import { PageContainer } from "./PageContainer";
import { LanguageSwitcher } from "./languageSwitcher/LanguageSwitcher";

export interface ButtonMenuFields {
  button: Entry<ButtonFields>;
  subButtons?: Entry<ButtonFields>[];
}

type HeaderItems = ButtonFields | ButtonMenuFields | DividerFields;

export interface HeaderFields {
  items?: Entry<HeaderItems>[];
}

interface HeaderProps {
  fields: HeaderFields;
  languages?: Entry<LanguageFields>[];
  translatedPageLinks: UntranslatedField<string>;
  locale: string;
}

export const Header: React.FC<HeaderProps> = ({
  fields,
  languages,
  translatedPageLinks,
  locale,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const btnRef = useRef();

  const desktop = useBreakpointValue({ base: false, md: true });

  useEffect(() => {
    onClose();
  }, [desktop]);

  return (
    <>
      <Box
        as="nav"
        h="88px"
        w="100%"
        bg="white"
        borderBottom="2px"
        borderBottomColor="gray.200"
        zIndex="sticky"
        position="fixed"
      >
        <PageContainer
          display="flex"
          h="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <HeaderLogo />

          <Spacer />

          <Button
            ref={btnRef}
            display={{ base: "block", lg: "none" }}
            onClick={onToggle}
            mr={4}
            variant="unstyled"
          >
            {isOpen ? (
              <CloseIcon aria-label="Close navigation menu" boxSize={5} />
            ) : (
              <HamburgerIcon boxSize={6} aria-label="Open navigation menu" />
            )}
          </Button>
          <Box display={{ base: "none", lg: "flex" }}>
            <DesktopItems
              items={fields.items}
              onClose={onClose}
              locale={locale}
              languages={languages}
              translatedPageLinks={translatedPageLinks}
            />
          </Box>
        </PageContainer>
      </Box>

      <Drawer
        isOpen={isOpen}
        placement="top"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <Box h="88px">
            <DrawerCloseButton />
          </Box>
          <DrawerBody py={6}>
            <MobileItems
              items={fields.items}
              onClose={onClose}
              locale={locale}
              languages={languages}
              translatedPageLinks={translatedPageLinks}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box h="88px" />
    </>
  );
};

interface ItemsProps {
  items: Entry<HeaderItems>[];
  onClose: () => void;
  languages?: Entry<LanguageFields>[];
  translatedPageLinks: UntranslatedField<string>;
  locale: string;
}

const MobileItems: React.FC<ItemsProps> = ({
  items,
  onClose,
  languages,
  translatedPageLinks,
  locale,
}) => {
  const components = items.map((item, index) => {
    const key = `${item.sys.id}-${index}`;

    switch (item.sys.contentType.sys.id) {
      case "button":
        return (
          <CmsButton
            key={key}
            fields={item.fields as ButtonFields}
            buttonProps={{ onClick: onClose, my: 2 }}
          />
        );
      case "buttonMenu": {
        const itemFields = item.fields as ButtonMenuFields;

        return (
          <Accordion key={key} allowMultiple>
            <AccordionItem border={0}>
              <h2>
                <AccordionButton bg="none" px={0} _hover={{ bg: "none" }}>
                  <Box flex="1" textAlign="left" fontWeight="semibold">
                    {itemFields.button.fields.text}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} px={0} pl={2}>
                <VStack align="flex-start">
                  {itemFields.subButtons.map((button, index) => (
                    <CmsButton
                      key={`${button.sys.id}-${index}`}
                      fields={button.fields}
                      buttonProps={{ onClick: onClose }}
                    />
                  ))}
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        );
      }
      case "divider":
        return <Divider key={key} orientation="horizontal" />;
    }
  });

  return (
    <VStack spacing={4} align="stretch">
      {components}
      <div>
        <LanguageSwitcher
          languages={languages}
          translatedPageLinks={translatedPageLinks}
        >
          {
            languages.find(
              (footerLanguage) => footerLanguage.fields.code === locale
            )?.fields?.name
          }
        </LanguageSwitcher>
      </div>
    </VStack>
  );
};

const DesktopItems: React.FC<ItemsProps> = ({
  items,
  languages,
  translatedPageLinks,
  locale,
}) => {
  const components = items.map((item, index) => {
    const key = `${item.sys.id}-${index}`;

    switch (item.sys.contentType.sys.id) {
      case "button":
        return <CmsButton key={key} fields={item.fields as ButtonFields} />;
      case "buttonMenu": {
        return (
          <DesktopDropdown key={key} fields={item.fields as ButtonMenuFields} />
        );
      }
      case "divider":
        return <Divider key={key} orientation="vertical" h="40px" />;
    }
  });

  return (
    <HStack spacing={[null, null, null, 6, 8]}>
      {components}
      <LanguageSwitcher
        languages={languages}
        translatedPageLinks={translatedPageLinks}
      >
        {
          languages.find(
            (footerLanguage) => footerLanguage.fields.code === locale
          )?.fields?.name
        }
      </LanguageSwitcher>
    </HStack>
  );
};

interface DesktopDropdownProps {
  fields: ButtonMenuFields;
}

const DesktopDropdown: React.FC<DesktopDropdownProps> = ({ fields }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isClosingRef = useRef(false);

  const handleMouseLeave = useCallback(() => {
    isClosingRef.current = true;

    setTimeout(() => {
      if (isClosingRef.current) {
        onClose();
      }
    }, 250);
  }, [onClose]);

  const handleMouseOver = useCallback(() => {
    isClosingRef.current = false;
    onOpen();
  }, []);

  return (
    <Menu placement="bottom" isOpen={isOpen} onOpen={onOpen}>
      <MenuButton
        as={Button}
        {...mapButtonProps(fields.button.fields)}
        rightIcon={<ChevronDownIcon />}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      />

      <MenuList
        p={5}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <VStack spacing={3} align="flex-start">
          {fields.subButtons.map((button, index) => (
            <CmsButton
              key={`${button.sys.id}-${index}`}
              fields={button.fields}
            />
          ))}
        </VStack>
      </MenuList>
    </Menu>
  );
};

const HeaderLogo: React.FC = () => {
  return (
    (<Link href="/">

      <Image src="/static/images/logo.svg" h="28px" alt="Propty logo" />

    </Link>)
  );
};
