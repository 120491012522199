import { Box } from "@chakra-ui/react";
import React from "react";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { lightTextOpacity } from "../../theme";
import { RichTextDocument } from "../../types/contentful";

export interface NumbersItemFields {
  title?: RichTextDocument;
  description?: RichTextDocument;
}

interface NumbersItemProps {
  fields: NumbersItemFields;
}

export const NumbersItem: React.FC<NumbersItemProps> = ({ fields }) => {
  return (
    <>
      {fields.title && (
        <Box textStyle="title" fontSize={["40px", null, "56px", null]}>
          <RichTextRenderer text={fields.title} as="h3" lineHeight={1} />
        </Box>
      )}

      {fields.description && (
        <Box fontSize="24px" opacity={lightTextOpacity}>
          <RichTextRenderer text={fields.description} />
        </Box>
      )}
    </>
  );
};
