import {
  Button,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Entry } from "contentful";
import React from "react";
import { LanguageFields, UntranslatedField } from "../../types/contentful";
import { CmsLink } from "../CmsLink";
import GlobeIcon from "./globe.svg";

interface LanguageSwitcherProps {
  languages: Entry<LanguageFields>[];
  translatedPageLinks: UntranslatedField<string>;
  menuButtonProps?: MenuButtonProps;
}

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  languages,
  translatedPageLinks,
  children,
  menuButtonProps,
}) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        color="#96A4B2"
        fontWeight={600}
        variant="link"
        _active={{}}
        leftIcon={
          <GlobeIcon
            style={{
              width: "16px",
              height: "16px",
            }}
          />
        }
        {...menuButtonProps}
      >
        {children}
      </MenuButton>
      <MenuList>
        {languages.map((footerLanguage, index) => (
          <CmsLink
            key={`${footerLanguage.sys.id}-${index}`}
            link={translatedPageLinks[footerLanguage.fields.code]}
            color="black"
            onClick={() => setLanguageCookie(footerLanguage.fields.code)}
          >
            <MenuItem>{footerLanguage.fields.name}</MenuItem>
          </CmsLink>
        ))}
      </MenuList>
    </Menu>
  );
};

function setLanguageCookie(locale: string) {
  document.cookie = `NEXT_LOCALE=${locale}`;
}
