import { Entry } from "contentful";
import React from "react";
import { Modules } from "../types/contentful";
import { AccordionModule } from "./accordion/AccordionModule";
import { ContactUsModule } from "./contactUs/ContactUsModule";
import { HeroModule } from "./hero/HeroModule";
import { HighlightModule } from "./highlight/HighlightModule";
import { LetterModule } from "./letter/LetterModule";
import { NumbersModule } from "./numbers/NumbersModule";
import { PricingModule } from "./pricing/PricingModule";
import { SpacingModule } from "./spacing/SpacingModule";
import { SpotlightModule } from "./spotlight/SpotlightModule";
import { TestimonialModule } from "./testimonial/TestimonialModule";
import { TextModule } from "./text/TextModule";

interface ModuleProps {
  module: Entry<Modules>;
}

interface ModuleFields {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: Record<string, any>;
}

const moduleMap: Record<string, React.ElementType<ModuleFields>> = {
  highlightModule: HighlightModule,
  testimonialModule: TestimonialModule,
  textModule: TextModule,
  spacingModule: SpacingModule,
  numbersModule: NumbersModule,
  accordionModule: AccordionModule,
  letterModule: LetterModule,
  heroModule: HeroModule,
  contactUsModule: ContactUsModule,
  spotlightModule: SpotlightModule,
  pricingModule: PricingModule,
};

export const Module: React.FC<ModuleProps> = ({ module }) => {
  const Component = moduleMap[module?.sys?.contentType?.sys?.id];

  if (!Component) {
    return null;
  }

  return <Component fields={module.fields} />;
};
