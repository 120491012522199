import { Asset } from "contentful";
import React from "react";
import { ImageRenderer, ImageRendererProps } from "./ImageRenderer";

interface ResponsiveImageProps extends ImageRendererProps {
  desktopImage?: Asset;
  mobileImage?: Asset;
  // Can use sizes for both mobile/desktop
  sizes?: unknown[];
  // Prefer sizes, as it can be used for both
  mobileSizes?: unknown[];
  desktopSizes?: unknown[];
}

export const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
  mobileImage,
  desktopImage,
  sizes,
  mobileSizes,
  desktopSizes,
  ...props
}) => {
  return (
    <>
      {mobileImage && (
        <ImageRenderer
          {...props}
          asset={mobileImage}
          sizes={mobileSizes || sizes}
          display={["block", null, desktopImage ? "none" : null, null]}
        />
      )}
      {desktopImage && (
        <ImageRenderer
          {...props}
          asset={desktopImage}
          sizes={desktopSizes || sizes}
          display={["none", null, "block", null]}
        />
      )}
    </>
  );
};
