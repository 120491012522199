import { Box, Container } from "@chakra-ui/react";
import { Entry } from "contentful";
import React from "react";
import { Background, BackgroundFields } from "../../components/Background";
import { PageContainer } from "../../components/PageContainer";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { lightTextOpacity } from "../../theme";
import { RichTextDocument } from "../../types/contentful";

export interface LetterModuleFields {
  title?: RichTextDocument;
  subtitle?: RichTextDocument;
  text?: RichTextDocument;
  background?: Entry<BackgroundFields>;
}

interface LetterModuleProps {
  fields: LetterModuleFields;
}

export const LetterModule: React.FC<LetterModuleProps> = ({ fields }) => {
  return (
    <Background fields={fields.background?.fields}>
      <PageContainer>
        <Box py={[16, null, 20, null]}>
          {fields.title && (
            <Box
              align="center"
              textStyle="title"
              fontSize="40px"
              mb={[4, null, 2, null]}
            >
              <RichTextRenderer
                text={fields.title}
                as="h2"
                lineHeight={[1.3, null, 1.5, null]}
              />
            </Box>
          )}

          {fields.subtitle && (
            <Box align="center" fontSize="20px" opacity={lightTextOpacity}>
              <RichTextRenderer text={fields.subtitle} />
            </Box>
          )}

          <Container maxW="container.sm" mt={5}>
            <Box
              bgColor="white"
              textColor="gray.700"
              p={8}
              shadow="md"
              borderRadius="md"
            >
              <RichTextRenderer text={fields.text} block />
            </Box>
          </Container>
        </Box>
      </PageContainer>
    </Background>
  );
};
