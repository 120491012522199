import { Box, Container, Flex, Stack } from "@chakra-ui/react";
import { Asset, Entry } from "contentful";
import React from "react";
import { Background, BackgroundFields } from "../../components/Background";
import { PageContainer } from "../../components/PageContainer";
import { ResponsiveImage } from "../../components/ResponsiveImage";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { lightTextOpacity } from "../../theme";
import { RichTextDocument } from "../../types/contentful";
import { SpotlightItem, SpotlightItemFields } from "./SpotlightItem";

export interface SpotlightModuleFields {
  title?: RichTextDocument;
  description?: RichTextDocument;
  eyebrow?: RichTextDocument;
  eyebrowColor?: string;
  desktopImage?: Asset;
  mobileImage?: Asset;
  imagePosition?: "bottom" | "left" | "right";
  mobileImagePosition?: "bottom" | "top";
  imageBorderRadius?: string;
  background?: Entry<BackgroundFields>;
  items?: Entry<SpotlightItemFields>[];
  center?: boolean;
}

interface SpotlightModuleProps {
  fields: SpotlightModuleFields;
}

const imageSizes = [
  {
    mediaQuery: "xs",
    params: { w: 400 },
  },
  {
    mediaQuery: "default",
    params: { w: 1000 },
  },
];

export const SpotlightModule: React.FC<SpotlightModuleProps> = ({ fields }) => {
  const align = fields.imagePosition === "bottom" ? "center" : "left";

  const eyebrow = fields.eyebrow && (
    <Box
      textStyle="title"
      align={align}
      fontSize="20px"
      color={fields.eyebrowColor}
    >
      <RichTextRenderer text={fields.eyebrow} />
    </Box>
  );

  const title = fields.title && (
    <Box
      textStyle="title"
      align={align}
      fontSize={["32px", null, "40px", null]}
      mb={12}
      mt={[0, null, -2, null]}
    >
      <RichTextRenderer text={fields.title} as="h2" />
    </Box>
  );

  const description = fields.description && (
    <Box
      align={align}
      fontSize="20px"
      fontWeight={600}
      opacity={lightTextOpacity}
      mb={12}
    >
      <RichTextRenderer text={fields.description} />
    </Box>
  );

  const image = (
    <Container maxW="container.md" px={0}>
      <Flex justify="center">
        <Box flex={1}>
          <ResponsiveImage
            mobileImage={fields.mobileImage}
            desktopImage={fields.desktopImage}
            sizes={imageSizes}
            borderRadius={fields.imageBorderRadius}
            mb={12}
            width="100%"
          />
        </Box>
      </Flex>
    </Container>
  );

  const spotlightItems = !!fields.items?.length && (
    <Flex
      mt={4}
      mx="auto"
      direction={["column", null, "row", null]}
      align="start"
      flexWrap="wrap"
      maxW="container.lg"
      sx={{ columnGap: "112px", rowGap: "80px" }}
    >
      {fields.items.map((item, index) => (
        <Box
          key={`${item.sys.id}-${index}`}
          width={[
            "100%",
            null,
            fields.imagePosition === "bottom"
              ? `calc(50% - ${112 / 2}px)`
              : "100%",
            null,
          ]}
        >
          <SpotlightItem fields={item.fields} />
        </Box>
      ))}
    </Flex>
  );

  function renderContent() {
    switch (fields.imagePosition) {
      case "bottom":
        return (
          <Flex py={[12, null, 24, null]} direction="column">
            <Box
              display={[
                fields.mobileImagePosition === "top" ? "block" : "none",
                null,
                "none",
                null,
              ]}
            >
              {" "}
              {image}
            </Box>
            {eyebrow}
            {title}
            {description}
            {spotlightItems}
            <Box
              display={[
                fields.mobileImagePosition === "top" ? "none" : "block",
                null,
                "block",
                null,
              ]}
            >
              {" "}
              {image}
            </Box>
          </Flex>
        );
      case "left":
      case "right":
        return (
          <Stack
            py={[12, null, 24, null]}
            direction={[
              fields.mobileImagePosition === "top"
                ? "column"
                : "column-reverse",
              null,
              fields.imagePosition === "left" ? "row" : "row-reverse",
              null,
            ]}
            spacing={[0, null, 16, null]}
            align={fields.center ? "center" : undefined}
          >
            <Box width={["100%", null, "60%", null]} align="center">
              {image}
            </Box>
            <Box width={["100%", null, "40%", null]} justifyContent="center">
              {eyebrow}
              {title}
              {description}
              {spotlightItems}
            </Box>
          </Stack>
        );
      default:
        return;
    }
  }

  return (
    <Background fields={fields.background?.fields}>
      <PageContainer>{renderContent()}</PageContainer>
    </Background>
  );
};
