import {
  Badge,
  Button,
  ButtonProps,
  chakra,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Entry } from "contentful";
import React from "react";
import { CmsLink, CmsLinkProps } from "./CmsLink";

interface ModalFields {
  iframeUrl?: string;
  title?: string;
  size?: string;
}

export interface ButtonFields {
  text?: string;
  link?: string;
  variant?: string;
  colorScheme?: string;
  textColor?: string;
  backgroundColor?: string;
  outlineColor?: string;
  hoverColor?: string;
  openLinkInNewTab?: boolean;
  badge?: string;
  badgeColorScheme?: string;
  modal?: Entry<ModalFields>;
}

interface CmsButtonProps {
  fields: ButtonFields;
  buttonProps?: ButtonProps;
  linkProps?: CmsLinkProps;
  disableLink?: boolean;
}

const ChakraIframe = chakra("iframe");

export const CmsButton: React.FC<CmsButtonProps> = ({
  fields,
  buttonProps,
  linkProps,
  disableLink,
}) => {
  const hasLink = !disableLink && fields.link;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Wrapper: React.FC = ({ children }) => {
    if (!fields.badge) {
      return <>{children}</>;
    }

    return (
      <Flex align="start">
        {children}
        <Badge
          ml={0.5}
          mt={-0.5}
          fontSize="10px"
          colorScheme={fields.badgeColorScheme}
        >
          {fields.badge}
        </Badge>
      </Flex>
    );
  };

  const buttonModalProps: ButtonProps = fields.modal?.fields
    ? {
        onClick: onOpen,
      }
    : {};

  const button = (
    <Button
      {...mapButtonProps(fields)}
      {...buttonProps}
      {...buttonModalProps}
      tabIndex={hasLink ? -1 : undefined}
    />
  );

  if (fields.modal?.fields) {
    return (
      <>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          size={fields.modal.fields.size || "4xl"}
        >
          <ModalOverlay />
          <ModalContent>
            {fields.modal.fields.title && (
              <ModalHeader>{fields.modal.fields.title}</ModalHeader>
            )}
            <ModalCloseButton />
            <ModalBody>
              {fields.modal.fields.iframeUrl && (
                <ChakraIframe
                  src={fields.modal.fields.iframeUrl}
                  w="100%"
                  h="80vh"
                />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
        {button}
      </>
    );
  }

  if (hasLink) {
    return (
      <Wrapper>
        <CmsLink
          {...linkProps}
          link={fields.link}
          openLinkInNewTab={fields.openLinkInNewTab}
          sx={{
            "&:hover": {
              textDecoration: fields.variant === "solid" ? "none" : undefined,
            },
          }}
        >
          {button}
        </CmsLink>
      </Wrapper>
    );
  }

  return <Wrapper>{button}</Wrapper>;
};

export function mapButtonProps(fields: ButtonFields | undefined): ButtonProps {
  return {
    colorScheme: fields?.colorScheme,
    textColor: fields?.textColor,
    backgroundColor: fields?.backgroundColor,
    borderColor: fields?.outlineColor,
    variant: fields?.variant,
    children: fields?.text,
    _hover: fields?.hoverColor
      ? { backgroundColor: fields.hoverColor }
      : undefined,
    _active: {
      bg: fields?.hoverColor,
    },
  };
}
