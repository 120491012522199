import { Box, Flex, Stack } from "@chakra-ui/react";
import { Asset, Entry } from "contentful";
import React from "react";
import { lightTextOpacity } from "../theme";
import { RichTextDocument } from "../types/contentful";
import { ButtonFields, CmsButton } from "./CmsButton";
import { ImageRenderer } from "./ImageRenderer";
import { PageContainer } from "./PageContainer";
import { RichTextRenderer } from "./RichTextRenderer";

export interface NotFoundFields {
  title?: RichTextDocument;
  description?: RichTextDocument;
  buttons: Entry<ButtonFields>[];
  image?: Asset;
}

interface NotFoundProps {
  fields: NotFoundFields;
}

const imageSizes = [
  {
    mediaQuery: "default",
    params: { w: 380 },
  },
];

export const NotFound: React.FC<NotFoundProps> = ({ fields }) => {
  return (
    <PageContainer>
      <Flex
        py={["80px", null, "160px"]}
        align="center"
        direction={["column", null, "row", null]}
      >
        <Box flex={1}>
          {fields.title && (
            <Box textStyle="title" fontSize="56px" mb={4}>
              <RichTextRenderer text={fields.title} as="h1" />
            </Box>
          )}

          {fields.description && (
            <Box fontSize="24px" mb={10} opacity={lightTextOpacity}>
              <RichTextRenderer text={fields.description} />
            </Box>
          )}

          {!!fields.buttons?.length && (
            <Stack
              direction={["column", null, "row", null]}
              spacing={4}
              mb={10}
            >
              {fields.buttons?.map((button, index) => (
                <CmsButton
                  key={`${button.sys.id}-${index}`}
                  fields={button.fields}
                  buttonProps={{ size: "lg" }}
                />
              ))}
            </Stack>
          )}
        </Box>
        <Box flex={1}>
          {fields.image && (
            <Flex mb={8} justify="center">
              <ImageRenderer
                asset={fields.image}
                sizes={imageSizes}
                w="380px"
              />
            </Flex>
          )}
        </Box>
      </Flex>
    </PageContainer>
  );
};
