import { Box, Flex, HStack, Stack } from "@chakra-ui/react";
import { Asset, Entry } from "contentful";
import React from "react";
import { Background, BackgroundFields } from "../../components/Background";
import { ButtonFields, CmsButton } from "../../components/CmsButton";
import { CmsLinkImage, LinkImageFields } from "../../components/CmsLinkImage";
import { PageContainer } from "../../components/PageContainer";
import { ResponsiveImage } from "../../components/ResponsiveImage";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { lightestTextOpacity, lightTextOpacity } from "../../theme";
import { RichTextDocument } from "../../types/contentful";

export interface HeroModuleFields {
  title?: RichTextDocument;
  description?: RichTextDocument;
  desktopImage?: Asset;
  mobileImage?: Asset;
  buttons?: Entry<ButtonFields>[];
  background?: Entry<BackgroundFields>;
  itemsTitle?: RichTextDocument;
  items?: Entry<LinkImageFields>[];
}

interface HeroModuleProps {
  fields: HeroModuleFields;
}

const imageSizes = [
  {
    mediaQuery: "sm",
    params: { w: 448 },
  },
  {
    mediaQuery: "md",
    params: { w: 352 },
  },
  {
    mediaQuery: "lg",
    params: { w: 464 },
  },
  {
    mediaQuery: "xl",
    params: { w: 608 },
  },
];

export const HeroModule: React.FC<HeroModuleProps> = ({ fields }) => {
  return (
    <Background fields={fields.background?.fields}>
      <PageContainer>
        <Box py={[16, null, 20, null]} pt={[8, null, 20, null]}>
          <Flex direction={["column", null, "row", null]}>
            <Box flex={1} mr={[0, null, 8, null]} mb={[8, null, 0, null]}>
              {fields.title && (
                <Box textStyle="title" fontSize="40px" mb={4}>
                  <RichTextRenderer text={fields.title} as="h2" />
                </Box>
              )}

              {fields.description && (
                <Box
                  fontSize="20px"
                  fontWeight="semibold"
                  opacity={lightTextOpacity}
                  mb={8}
                >
                  <RichTextRenderer text={fields.description} />
                </Box>
              )}

              {!!fields.buttons?.length && (
                <Stack
                  align="center"
                  direction={["column", null, "row", null]}
                  spacing={4}
                  mb={8}
                >
                  {fields.buttons?.map((button, index) => (
                    <CmsButton
                      key={`${button.sys.id}-${index}`}
                      fields={button.fields}
                      buttonProps={{ size: "lg" }}
                    />
                  ))}
                </Stack>
              )}

              {!!fields.items?.length && (
                <Box display={["none", null, "block", null]}>
                  {fields.itemsTitle && (
                    <Box
                      opacity={lightestTextOpacity}
                      fontWeight="semibold"
                      mb={2}
                    >
                      <RichTextRenderer text={fields.itemsTitle} as="h2" />
                    </Box>
                  )}

                  <HStack spacing={4}>
                    {fields.items?.map((item, index) => (
                      <Flex key={`${item.sys.id}-${index}`}>
                        <CmsLinkImage fields={item.fields} maxHeight="50px" />
                      </Flex>
                    ))}
                  </HStack>
                </Box>
              )}
            </Box>

            <Box flex={1}>
              <ResponsiveImage
                mobileImage={fields.mobileImage}
                desktopImage={fields.desktopImage}
                sizes={imageSizes}
              />
            </Box>
          </Flex>
        </Box>
      </PageContainer>
    </Background>
  );
};
