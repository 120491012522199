import { Box, Flex, Stack, VStack } from "@chakra-ui/react";
import { Entry } from "contentful";
import React from "react";
import { Background, BackgroundFields } from "../../components/Background";
import { PageContainer } from "../../components/PageContainer";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { lightTextOpacity } from "../../theme";
import { RichTextDocument } from "../../types/contentful";
import { NumbersItem, NumbersItemFields } from "./NumbersItem";

export interface NumbersModuleFields {
  title?: RichTextDocument;
  description?: RichTextDocument;
  background?: Entry<BackgroundFields>;
  items?: Entry<NumbersItemFields>[];
}

interface NumbersModuleProps {
  fields: NumbersModuleFields;
}

export const NumbersModule: React.FC<NumbersModuleProps> = ({ fields }) => {
  return (
    <Background fields={fields.background?.fields}>
      <PageContainer>
        <Box py={[16, null, 20, null]}>
          <Stack direction={["column", null, "row", null]} spacing={8}>
            <VStack
              w={["100%", null, "50%", null]}
              order={[-1, null, 1, null]}
              mt={[null, null, null, null]}
              pl={[null, null, 8, null]}
              spacing={4}
              align="start"
            >
              {fields.title && (
                <Box textStyle="title" fontSize="40px" mt={-2}>
                  <RichTextRenderer text={fields.title} as="h2" />
                </Box>
              )}

              {fields.description && (
                <Box
                  fontSize="20px"
                  fontWeight={600}
                  mb={8}
                  opacity={lightTextOpacity}
                >
                  <RichTextRenderer text={fields.description} />
                </Box>
              )}
            </VStack>

            {!!fields.items?.length && (
              <Flex
                w={["100%", null, "50%", null]}
                wrap="wrap"
                direction={["column", null, "row", null]}
                justify="center"
                m={-4}
                pr={[null, null, 8, null]}
              >
                {fields.items?.map((item, index) => (
                  <Box
                    key={`${item.sys.id}-${index}`}
                    w={["100%", null, "50%", null]}
                    pb={8}
                  >
                    <NumbersItem fields={item.fields} />
                  </Box>
                ))}
              </Flex>
            )}
          </Stack>
        </Box>
      </PageContainer>
    </Background>
  );
};
