import { Box, BoxProps, Flex, VStack } from "@chakra-ui/react";
import { Asset } from "contentful";
import React from "react";
import { ImageRenderer } from "../../components/ImageRenderer";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { lightTextOpacity } from "../../theme";
import { RichTextDocument } from "../../types/contentful";

export interface SpotlightItemFields {
  icon?: Asset;
  iconWidth?: number;
  title?: RichTextDocument;
  description?: RichTextDocument;
}

interface CmsChecklistProps {
  fields: SpotlightItemFields;
  center?: boolean;
  titleProps?: BoxProps;
}

export const SpotlightItem: React.FC<CmsChecklistProps> = ({
  fields,
  center,
  titleProps,
}) => {
  const iconWidth = fields.iconWidth || 24;

  return (
    <Flex align={center ? "center" : undefined}>
      {fields.icon && (
        <Flex mt={1.5} mr={3} ml={1}>
          <ImageRenderer
            asset={fields.icon}
            sizes={[
              {
                mediaQuery: "default",
                params: { w: iconWidth },
              },
            ]}
            width={`${iconWidth}px`}
          />
        </Flex>
      )}
      <VStack spacing={2} align="start">
        {fields.title && (
          <Box textStyle="title" fontSize="24px" {...titleProps}>
            <RichTextRenderer text={fields.title} as="h3" noPadding />
          </Box>
        )}
        {fields.description && (
          <Box opacity={lightTextOpacity}>
            <RichTextRenderer text={fields.description} noPadding />
          </Box>
        )}
      </VStack>
    </Flex>
  );
};
