import { Asset } from "contentful";
import React from "react";
import { CmsLink, CmsLinkProps } from "./CmsLink";
import { ImageRenderer, ImageRendererProps } from "./ImageRenderer";

export interface LinkImageFields {
  image?: Asset;
  link?: string;
}

interface CmsLinkImageProps {
  fields: LinkImageFields;
  sizes?: unknown[];
  imageProps?: ImageRendererProps;
  linkProps?: CmsLinkProps;
  height?: string;
  width?: string;
  maxHeight?: string;
  maxWidth?: string;
}

export const CmsLinkImage: React.FC<CmsLinkImageProps> = ({
  fields,
  sizes,
  imageProps,
  linkProps,
  height,
  width,
  maxHeight,
  maxWidth,
}) => {
  const image = (
    <ImageRenderer
      height={height}
      width={width}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      {...imageProps}
      sizes={sizes}
      asset={fields.image}
    />
  );

  if (!fields.link) {
    return image;
  }

  return (
    <CmsLink {...linkProps} link={fields.link}>
      {image}
    </CmsLink>
  );
};
