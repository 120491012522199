import { Box } from "@chakra-ui/react";
import React from "react";

export interface SpacingModuleFields {
  height?: string;
}

interface SpacingModuleProps {
  fields: SpacingModuleFields;
}

export const SpacingModule: React.FC<SpacingModuleProps> = ({ fields }) => {
  return <Box h={fields.height} />;
};
