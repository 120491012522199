import { Box, Flex, HStack, Image, VStack } from "@chakra-ui/react";
import { Entry } from "contentful";
import React from "react";
import {
  LanguageFields,
  RichTextDocument,
  UntranslatedField,
} from "../types/contentful";
import { CmsLink } from "./CmsLink";
import { CmsLinkImage, LinkImageFields } from "./CmsLinkImage";
import { PageContainer } from "./PageContainer";
import { RichTextRenderer } from "./RichTextRenderer";
import { LanguageSwitcher } from "./languageSwitcher/LanguageSwitcher";

interface FooterSectionItemFields {
  text: string;
  link?: string;
}

interface FooterSectionFields {
  title: string;
  items?: Entry<FooterSectionItemFields>[];
}

export interface FooterFields {
  description?: RichTextDocument;
  sections?: Entry<FooterSectionFields>[];
  icons?: Entry<LinkImageFields>[];
  stores?: Entry<LinkImageFields>[];
  copyright?: string;
}

interface FooterProps {
  fields: FooterFields;
  locale: string;
  translatedPageLinks: UntranslatedField<string>;
  languages?: Entry<LanguageFields>[];
}

export const Footer: React.FC<FooterProps> = ({
  fields,
  locale,
  translatedPageLinks,
  languages,
}) => {
  return (
    <Box pt={12} backgroundColor="#2B2F33">
      <PageContainer>
        <Flex align="flex-start" direction={["column", null, null, "row"]}>
          <VStack maxWidth="sm" mr={10} mb={10} spacing={8} align="start">
            <Image
              src="/static/images/logo-white.svg"
              h="36px"
              alt="Propty logo"
            />
            {fields.description && (
              <Box fontWeight={600} color="#96A4B2">
                <RichTextRenderer text={fields.description} />
              </Box>
            )}

            {!!fields.icons?.length && (
              <HStack spacing={4} pt={4}>
                {fields.icons?.map((icon, index) => (
                  <Flex
                    key={`${icon.sys.id}-${index}`}
                    minW="24px"
                    justify="center"
                  >
                    <CmsLinkImage
                      fields={icon.fields}
                      sizes={[{ mediaQuery: "default", params: { h: 24 } }]}
                      height="24px"
                    />
                  </Flex>
                ))}
              </HStack>
            )}

            {!!fields.stores?.length && (
              <HStack spacing={4} pt={6}>
                {fields.stores?.map((icon, index) => (
                  <Flex key={`${icon.sys.id}-${index}`}>
                    <CmsLinkImage
                      fields={icon.fields}
                      maxHeight="40px"
                      sizes={[{ mediaQuery: "default", params: { h: 40 } }]}
                    />
                  </Flex>
                ))}
              </HStack>
            )}
          </VStack>

          {fields.sections?.map((section, index) => (
            <VStack
              key={`${section.sys.id}-${index}`}
              align="flex-start"
              spacing={6}
              mr={12}
              mb={10}
            >
              <Box as="h3" color="white" fontWeight={700}>
                {section.fields.title}
              </Box>
              {section.fields.items?.map((item, index) => (
                <CmsLink
                  key={`${item.sys.id}-${index}`}
                  link={item.fields.link}
                  color="#96A4B2"
                  fontWeight={600}
                >
                  {item.fields.text}
                </CmsLink>
              ))}
            </VStack>
          ))}
        </Flex>

        <Flex
          align="flex-start"
          justify="space-between"
          direction={["column", null, null, "row"]}
          mt={[12, null, null, 24]}
        >
          <Box color="#96A4B2" fontWeight={600} mb={10}>
            {fields.copyright}
          </Box>
          <Box order={[-1, null, null, 1]} mb={10}>
            <LanguageSwitcher
              languages={languages}
              translatedPageLinks={translatedPageLinks}
            >
              {
                languages.find(
                  (footerLanguage) => footerLanguage.fields.code === locale
                ).fields.name
              }
            </LanguageSwitcher>
          </Box>
        </Flex>
      </PageContainer>
    </Box>
  );
};
