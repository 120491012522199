import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, IconButton } from "@chakra-ui/react";
import { Entry } from "contentful";
import React from "react";
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";
import { Background, BackgroundFields } from "../../components/Background";
import {
  PageContainer,
  pageContainerMobilePadding,
} from "../../components/PageContainer";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { RichTextDocument } from "../../types/contentful";
import { TestimonialItem, TestimonialItemFields } from "./TestimonialItem";
// eslint-disable-next-line import/no-unresolved
import "swiper/css";

export interface TestimonialModuleFields {
  title?: RichTextDocument;
  eyebrow?: RichTextDocument;
  eyebrowColor?: string;
  background?: Entry<BackgroundFields>;
  items?: Entry<TestimonialItemFields>[];
}

interface TestimonialModuleProps {
  fields: TestimonialModuleFields;
}

export const TestimonialModule: React.FC<TestimonialModuleProps> = ({
  fields,
}) => {
  const swiperRef = React.useRef(null);

  return (
    <Background fields={fields.background?.fields}>
      <PageContainer>
        <Box py={[16, null, 20, null]}>
          {fields.eyebrow && (
            <Box
              textStyle="title"
              align="center"
              fontSize="20px"
              color={fields.eyebrowColor}
            >
              <RichTextRenderer text={fields.eyebrow} />
            </Box>
          )}

          {fields.title && (
            <Box textStyle="title" align="center" fontSize="40px" mb={10}>
              <RichTextRenderer text={fields.title} as="h2" />
            </Box>
          )}

          {!!fields.items?.length && (
            <Box mx={["-" + pageContainerMobilePadding[0], null, 0, null]}>
              <Swiper
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                ref={swiperRef}
                loop
              >
                {fields.items?.map((item, index) => (
                  <SwiperSlide key={`${item.sys.id}-${index}`}>
                    <TestimonialItem fields={item.fields} />
                  </SwiperSlide>
                ))}
                <IconButton
                  onClick={() => swiperRef.current.swiper.slidePrev()}
                  position="absolute"
                  top={["calc(50% - 64px)", null, "calc(50% - 128px)", null]}
                  left={0}
                  zIndex="docked"
                  aria-label="Previous"
                  h={["40px", null, "56px", null]}
                  ml={[3, null, 1, null]}
                  icon={
                    <ChevronLeftIcon
                      h={["24px", null, "56px"]}
                      w={["32px", null, "56px"]}
                    />
                  }
                  variant="unstyled"
                  borderRadius="full"
                  boxShadow="lg"
                  bg="white"
                  _focus={{ boxShadow: "var(--chakra-shadows-lg) !important" }}
                />
                <IconButton
                  onClick={() => swiperRef.current.swiper.slideNext()}
                  position="absolute"
                  top={["calc(50% - 64px)", null, "calc(50% - 128px)", null]}
                  right={0}
                  zIndex="docked"
                  aria-label="Next"
                  h={["40px", null, "56px", null]}
                  mr={[3, null, 1, null]}
                  icon={
                    <ChevronRightIcon
                      h={["24px", null, "56px"]}
                      w={["32px", null, "56px"]}
                    />
                  }
                  variant="unstyled"
                  borderRadius="full"
                  boxShadow="lg"
                  bg="white"
                  _focus={{ boxShadow: "var(--chakra-shadows-lg) !important" }}
                />
              </Swiper>
            </Box>
          )}
        </Box>
      </PageContainer>
    </Background>
  );
};
