import { Box, Flex, Stack } from "@chakra-ui/react";
import { Asset, Entry } from "contentful";
import React from "react";
import { Background, BackgroundFields } from "../../components/Background";
import { ButtonFields, CmsButton } from "../../components/CmsButton";
import { ImageRenderer } from "../../components/ImageRenderer";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { lightTextOpacity } from "../../theme";
import { RichTextDocument } from "../../types/contentful";

export interface HighlightItemFields {
  title?: RichTextDocument;
  description?: RichTextDocument;
  image?: Asset;
  background?: Entry<BackgroundFields>;
  buttons?: Entry<ButtonFields>[];
  imageWidth?: string;
  contentAlignment?: string;
}

interface HighlightItemProps {
  fields: HighlightItemFields;
}

const imageSizes = [
  {
    mediaQuery: "default",
    params: { w: 120 },
  },
];

export const HighlightItem: React.FC<HighlightItemProps> = ({ fields }) => {
  const contentAlignment = fields.contentAlignment || "center";

  return (
    <Background fields={fields.background?.fields}>
      {fields.image && (
        <Flex mb={8} justify="center">
          <ImageRenderer
            asset={fields.image}
            sizes={imageSizes}
            maxWidth="100%"
            w={fields.imageWidth || "120px"}
          />
        </Flex>
      )}

      {fields.title && (
        <Box
          align={contentAlignment}
          textStyle="title"
          fontSize="24px"
          pr={4}
          pl={4}
          mb={4}
        >
          <RichTextRenderer text={fields.title} as="h3" />
        </Box>
      )}

      {fields.description && (
        <Box
          mb={4}
          pl={4}
          pr={4}
          pb={4}
          align={contentAlignment}
          opacity={lightTextOpacity}
        >
          <RichTextRenderer text={fields.description} />
        </Box>
      )}

      {!!fields.buttons?.length && (
        <Stack
          align={contentAlignment}
          direction={["column", null, "row", null]}
          spacing={4}
          mb={8}
          padding={4}
        >
          {fields.buttons?.map((button, index) => (
            <CmsButton
              key={`${button.sys.id}-${index}`}
              fields={button.fields}
              buttonProps={{ size: "md" }}
            />
          ))}
        </Stack>
      )}
    </Background>
  );
};
