import { Box, Flex, Stack, Text, VStack } from "@chakra-ui/react";
import { Entry } from "contentful";
import React from "react";
import { ButtonFields, CmsButton } from "../../components/CmsButton";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { lightestTextOpacity } from "../../theme";
import { RichTextDocument } from "../../types/contentful";
import { SpotlightItem, SpotlightItemFields } from "../spotlight/SpotlightItem";

export interface PricingCardFields {
  title?: RichTextDocument;
  description?: RichTextDocument;
  pricing?: never;
  pricingDescription?: RichTextDocument;
  buttons?: Entry<ButtonFields>[];
  features?: Entry<SpotlightItemFields>[];
  callout?: RichTextDocument;
}

export interface PricingTabFields {
  title?: RichTextDocument;
  cards?: Entry<PricingCardFields>[];
}

interface PricingTabProps {
  fields: PricingTabFields;
  units: number;
  limitButtons?: Entry<ButtonFields>[];
  reachedLimit: boolean;
}

export const PricingTab: React.FC<PricingTabProps> = ({
  fields,
  units,
  limitButtons,
  reachedLimit,
}) => {
  const hasCallout = fields.cards.some((card) => card.fields.callout);

  return (
    <Stack
      direction={["column", null, "row", null]}
      justify="center"
      spacing={6}
    >
      {fields.cards.map((card, index) => (
        <Box key={`${card.sys.id}-${index}`} w="380px">
          <Box
            mt={hasCallout && !card.fields.callout ? "46px" : 0}
            border="2px"
            borderColor={card.fields.callout ? "#2B2F33" : "gray.200"}
            borderRadius="2xl"
            textAlign="left"
          >
            {card.fields.callout && (
              <Flex
                bgColor="#2B2F33"
                borderTopRadius="xl"
                color="white"
                h="46px"
                align="center"
                justify="center"
              >
                <RichTextRenderer
                  text={card.fields.callout}
                  boxProps={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                />
              </Flex>
            )}
            <Box p={5}>
              <RichTextRenderer
                text={card.fields.title}
                boxProps={{ fontWeight: "bold", fontSize: "24px" }}
              />
              <RichTextRenderer
                text={card.fields.description}
                boxProps={{
                  opacity: lightestTextOpacity,
                  fontWeight: "semibold",
                  mb: 3,
                  minHeight: "3em",
                }}
              />
              <Text fontSize="32px" fontWeight="bold">
                {card.fields.pricing ? (
                  displayPrice(calculatePrice(units, card.fields.pricing))
                ) : (
                  <>&#8203;</>
                )}
              </Text>
              {card.fields.pricingDescription ? (
                <RichTextRenderer
                  text={card.fields.pricingDescription}
                  boxProps={{
                    opacity: lightestTextOpacity,
                    fontWeight: "semibold",
                    mb: 5,
                  }}
                />
              ) : (
                <Text mb={5}>&#8203;</Text>
              )}
              <VStack spacing={3} align="stretch" mb={6}>
                {((reachedLimit && limitButtons) || card.fields.buttons).map(
                  (button, index) => (
                    <CmsButton
                      key={`${button.sys.id}-${index}`}
                      fields={button.fields}
                      buttonProps={{
                        isFullWidth: true,
                        size: "lg",
                      }}
                    />
                  )
                )}
              </VStack>

              <VStack spacing={6} align="stretch" mb={3}>
                {card.fields.features.map((features, index) => (
                  <SpotlightItem
                    key={`${features.sys.id}-${index}`}
                    fields={features.fields}
                    center
                    titleProps={{
                      fontSize: "20px",
                      fontWeight: 500,
                      pt: 1,
                    }}
                  />
                ))}
              </VStack>
            </Box>
          </Box>
        </Box>
      ))}
    </Stack>
  );
};

export type Tier = {
  upTo: number;
} & (
  | {
      unitPrice: number;
    }
  | {
      flatPrice: number;
    }
);

// Calculates graduated Stripe pricing
function calculatePrice(quantity: number, tiers: Tier[]) {
  let price = 0;
  let unitsCounted = 0;

  for (const tier of tiers) {
    const unitsToCount =
      Math.min(quantity, tier.upTo ?? Infinity) - unitsCounted;

    if (unitsToCount <= 0) {
      break;
    }

    if ("flatPrice" in tier) {
      price = tier.flatPrice;
    } else if ("unitPrice" in tier) {
      price += unitsToCount * tier.unitPrice;
    }

    unitsCounted += unitsToCount;
  }

  return price;
}

// Turn decimal number price to formatted string
function displayPrice(price: number) {
  if (price === 0) {
    return "$0";
  }

  const stringPrice = price.toString().padStart(3, "0");

  return `$${stringPrice.slice(0, -2)}.${stringPrice.slice(-2)}`;
}
