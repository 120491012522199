import { Box } from "@chakra-ui/react";
import { Asset } from "contentful";
import React from "react";
import TriangleMask from "./triangle-mask.svg";

export interface BackgroundFields {
  backgroundColor?: string;
  textColor?: string;
  desktopImage?: Asset;
  mobileImage?: Asset;
  slant?: number;
  borderRadius?: string;
}

interface BackgroundProps {
  fields?: BackgroundFields;
}

export const Background: React.FC<BackgroundProps> = ({ fields, children }) => {
  const slantWidthMultiplier = Math.sin(
    (Math.abs(fields?.slant) * Math.PI) / 180
  );
  // Flip slant when < 0
  const transform = fields?.slant < 0 ? "rotateY(180deg)" : "";

  return (
    <Box
      color={fields?.textColor}
      borderRadius={fields?.borderRadius}
      bgColor={fields?.backgroundColor}
      bgImage={[
        fields?.mobileImage?.fields?.file?.url,
        null,
        fields?.desktopImage?.fields?.file?.url,
        null,
      ]}
      bgSize="cover"
    >
      {!!fields?.slant && (
        <TriangleMask
          style={{
            fill: "white",
            width: "100%",
            height: `calc(100vw * ${slantWidthMultiplier})`,
            transform,
          }}
          preserveAspectRatio="none"
        />
      )}

      {children}

      {!!fields?.slant && (
        <TriangleMask
          style={{
            fill: "white",
            width: "100%",
            height: `calc(100vw * ${slantWidthMultiplier})`,
            // Translate down by 1px to fix background/border artifact
            transform: `rotate(180deg) translateY(-1px) ${transform}`,
          }}
          preserveAspectRatio="none"
        />
      )}
    </Box>
  );
};
