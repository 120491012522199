import { Accordion, Box } from "@chakra-ui/react";
import { Entry } from "contentful";
import React from "react";
import { Background, BackgroundFields } from "../../components/Background";
import { PageContainer } from "../../components/PageContainer";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { RichTextDocument } from "../../types/contentful";
import { AccordionItem, AccordionItemFields } from "./AccordionItem";

export interface AccordionModuleFields {
  title?: RichTextDocument;
  background: Entry<BackgroundFields>;
  items: Entry<AccordionItemFields>[];
}

interface AccordionModuleProps {
  fields: AccordionModuleFields;
}

export const AccordionModule: React.FC<AccordionModuleProps> = ({ fields }) => {
  return (
    <Background fields={fields.background?.fields}>
      <PageContainer>
        <Box py={[16, null, 20, null]}>
          {fields.title && (
            <Box textStyle="title" align="center" fontSize="40px" mb={10}>
              <RichTextRenderer text={fields.title} />
            </Box>
          )}

          {!!fields.items?.length && (
            <Accordion>
              {fields.items?.map((item, index) => (
                <AccordionItem
                  key={`${item.sys.id}-${index}`}
                  fields={item.fields}
                />
              ))}
            </Accordion>
          )}
        </Box>
      </PageContainer>
    </Background>
  );
};
