import { Box } from "@chakra-ui/react";
import { Entry } from "contentful";
import React from "react";
import { Background, BackgroundFields } from "../../components/Background";
import { PageContainer } from "../../components/PageContainer";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { RichTextDocument } from "../../types/contentful";

export interface TextModuleFields {
  text?: RichTextDocument;
  background?: Entry<BackgroundFields>;
}

interface TextModuleProps {
  fields: TextModuleFields;
}

export const TextModule: React.FC<TextModuleProps> = ({ fields }) => {
  return (
    <Background fields={fields.background?.fields}>
      <PageContainer>
        <Box py={[16, null, 20, null]}>
          {fields.text && <RichTextRenderer text={fields.text} block />}
        </Box>
      </PageContainer>
    </Background>
  );
};
