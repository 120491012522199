import {
  AccordionButton,
  AccordionIcon,
  AccordionItem as ChakraAccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { RichTextDocument } from "../../types/contentful";

export interface AccordionItemFields {
  title: RichTextDocument;
  text?: RichTextDocument;
}

interface AccordionItemProps {
  fields: AccordionItemFields;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({ fields }) => {
  return (
    <ChakraAccordionItem>
      <h2>
        <AccordionButton>
          <Box fontWeight="semibold" fontSize="24px" flex={1} align="left">
            <RichTextRenderer
              text={fields.title}
              as="h3"
              textProps={{ pb: 0 }}
            />
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pt={3} pb={8} pr={10}>
        <RichTextRenderer text={fields.text} />
      </AccordionPanel>
    </ChakraAccordionItem>
  );
};
