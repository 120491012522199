import { Box, Flex, Stack, VStack } from "@chakra-ui/react";
import { Entry } from "contentful";
import React from "react";
import { Background, BackgroundFields } from "../../components/Background";
import { ButtonFields, CmsButton } from "../../components/CmsButton";
import { PageContainer } from "../../components/PageContainer";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { lightTextOpacity } from "../../theme";
import { RichTextDocument } from "../../types/contentful";
import { HighlightItem, HighlightItemFields } from "./HighlightItem";

export interface HighlightModuleFields {
  title?: RichTextDocument;
  description?: RichTextDocument;
  eyebrow?: RichTextDocument;
  eyebrowColor?: string;
  textColor?: string;
  buttons?: Entry<ButtonFields>[];
  background?: Entry<BackgroundFields>;
  items?: Entry<HighlightItemFields>[];
}

interface HighlightModuleProps {
  fields: HighlightModuleFields;
}

export const HighlightModule: React.FC<HighlightModuleProps> = ({ fields }) => {
  return (
    <Background fields={fields.background?.fields}>
      <PageContainer>
        <VStack py={[16, null, 20, null]} spacing={6} align="center">
          <div>
            {fields.eyebrow && (
              <Box
                textStyle="title"
                align="center"
                fontSize="22px"
                color={fields.eyebrowColor}
              >
                <RichTextRenderer text={fields.eyebrow} />
              </Box>
            )}
            {fields.title && (
              <Box textStyle="title" align="center" fontSize="40px">
                <RichTextRenderer text={fields.title} as="h2" />
              </Box>
            )}
          </div>

          {fields.description && (
            <Box align="center" fontSize="20px" opacity={lightTextOpacity}>
              <RichTextRenderer text={fields.description} />
            </Box>
          )}

          {!!fields.buttons?.length && (
            <Stack
              justify="center"
              align="center"
              direction={["column", null, "row", null]}
              spacing={4}
              pt={[4, null, 6, null]}
            >
              {fields.buttons?.map((button, index) => (
                <CmsButton
                  key={`${button.sys.id}-${index}`}
                  fields={button.fields}
                  buttonProps={{ size: "lg" }}
                />
              ))}
            </Stack>
          )}

          {!!fields.items?.length && (
            <Flex
              wrap="wrap"
              direction={["column", null, "row", null]}
              justify="center"
              mr={[0, null, -8, null]}
              pt={[4, null, 10, null]}
            >
              {fields.items?.map((item, index) => (
                <Box
                  key={`${item.sys.id}-${index}`}
                  w={["100%", null, "33.3%", null]}
                  pb={[12, null, 10, null]}
                  px={[0, null, 6, null]}
                >
                  <HighlightItem fields={item.fields} />
                </Box>
              ))}
            </Flex>
          )}
        </VStack>
      </PageContainer>
    </Background>
  );
};
