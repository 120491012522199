import {
  Box,
  ChakraProvider,
  Container,
  extendTheme,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

import { Entry } from "contentful";
import React, { useCallback, useEffect, useState } from "react";

import { Background, BackgroundFields } from "../../components/Background";
import { ButtonFields } from "../../components/CmsButton";
import { PageContainer } from "../../components/PageContainer";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { lightTextOpacity, theme as baseTheme } from "../../theme";
import { RichTextDocument } from "../../types/contentful";
import { PricingTab, PricingTabFields } from "./PricingTab";

export interface PricingModuleFields {
  title?: RichTextDocument;
  description?: RichTextDocument;
  tabs?: Entry<PricingTabFields>[];
  background?: Entry<BackgroundFields>;
  limitButtons?: Entry<ButtonFields>[];
  unitsLabel?: string;
  sliderLabel?: string;
  iframeUrl?: string;
}

interface PricingModuleProps {
  fields: PricingModuleFields;
}

const breakpoints = createBreakpoints({
  sm: "600px",
  md: "900px",
  lg: "1200px",
  xl: "1536px",
  "2xl": "1800px",
});

const theme = extendTheme({ ...baseTheme, breakpoints });

const maxUnits = 1000;

export const PricingModule: React.FC<PricingModuleProps> = ({ fields }) => {
  const [units, setUnits] = useState(1);
  const handleUnitChange = useCallback((units: number) => {
    setUnits(units);
  }, []);

  const reachedLimit = units === maxUnits;
  const [height, setHeight] = useState(1360);

  useEffect(() => {
    function handleMessage(event) {
      try {
        if (event.data.type === "height") {
          if (event.data.height && height !== event.data.height) {
            setHeight(event.data.height);
          }
        }
        // eslint-disable-next-line no-empty
      } catch {}
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  if (fields.iframeUrl) {
    return (
      <ChakraProvider theme={theme}>
        <Background fields={fields.background?.fields}>
          <Box w="100%" h={`${height}px`}>
            <iframe
              src={fields.iframeUrl}
              scrolling="no"
              width="100%"
              height="100%"
            />
          </Box>
        </Background>
      </ChakraProvider>
    );
  }

  return (
    <Background fields={fields.background?.fields}>
      <PageContainer>
        <Box py={[16, null, 20, null]}>
          {fields.title && (
            <Box textStyle="title" align="center" fontSize="40px" mb={10}>
              <RichTextRenderer text={fields.title} as="h2" />
            </Box>
          )}
          {fields.description && (
            <Box align="center" opacity={lightTextOpacity} mb={12}>
              <RichTextRenderer text={fields.description} />
            </Box>
          )}

          <Container maxW="container.md" mb={4}>
            <Text mb={2}>
              {fields.sliderLabel}:{" "}
              <Text as="span" fontWeight="semibold">
                {units}
                {reachedLimit && "+"} {fields.unitsLabel}
              </Text>
            </Text>

            <Slider
              onChange={handleUnitChange}
              min={1}
              max={maxUnits}
              value={units}
            >
              <SliderTrack h="8px" bgColor="brand.100" borderRadius="full">
                <SliderFilledTrack bgColor="brand.500" />
              </SliderTrack>
              <SliderThumb
                h="24px"
                w="24px"
                borderColor="brand.500"
                border="2px"
              />
            </Slider>
          </Container>

          <Tabs align="center" lazyBehavior="keepMounted">
            <Flex justify="center" mb={8}>
              <TabList>
                {fields.tabs.map((tab, index) => (
                  <Tab
                    key={`${tab.sys.id}-${index}`}
                    _selected={{
                      fontWeight: 600,
                      borderBottom: "3px solid black",
                    }}
                    fontSize="20px"
                    px={8}
                  >
                    <RichTextRenderer text={tab.fields.title} />
                  </Tab>
                ))}
              </TabList>
            </Flex>
            <TabPanels>
              {fields.tabs.map((tab, index) => (
                <TabPanel key={`${tab.sys.id}-${index}`}>
                  <PricingTab
                    fields={tab.fields}
                    units={units}
                    limitButtons={fields.limitButtons}
                    reachedLimit={reachedLimit}
                  />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </PageContainer>
    </Background>
  );
};
