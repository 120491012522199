import { Box, HStack, VStack } from "@chakra-ui/react";
import { Asset } from "contentful";
import React from "react";
import { ImageRenderer } from "../../components/ImageRenderer";
import { RichTextRenderer } from "../../components/RichTextRenderer";
import { RichTextDocument } from "../../types/contentful";

export interface TestimonialItemFields {
  text?: RichTextDocument;
  name?: string;
  role?: string;
  icon?: Asset;
}

interface TestimonialItemProps {
  fields: TestimonialItemFields;
}

export const TestimonialItem: React.FC<TestimonialItemProps> = ({ fields }) => {
  return (
    <VStack mx={[16, null, 20 + 12]} spacing={[8, null, 16, null]}>
      {fields.text && (
        <Box
          fontSize={["16px", null, "24px", null]}
          mb={1}
          fontWeight="semibold"
          textAlign="center"
        >
          <RichTextRenderer text={fields.text} block />
        </Box>
      )}

      <HStack mt={2} justify="center" spacing={[4, null, 6, null]}>
        {fields.icon && (
          <Box
            h={["64px", null, "96px", null]}
            w={["64px", null, "96px", null]}
          >
            <ImageRenderer
              asset={fields.icon}
              sizes={[
                { mediaQuery: "default", params: { w: 64, h: 64 } },
                { mediaQuery: "md", params: { w: 96, h: 96 } },
              ]}
              borderRadius="full"
            />
          </Box>
        )}

        <VStack align="start" spacing={[1, null, 2, null]}>
          {fields.name && (
            <Box fontSize={["20px", null, "24px", null]} fontWeight="semibold">
              {fields.name}
            </Box>
          )}
          {fields.role && <div>{fields.role}</div>}
        </VStack>
      </HStack>
    </VStack>
  );
};
